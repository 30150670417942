import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  caseDetails;
  caseDetailsSB = new BehaviorSubject(null);
  caseList = [];
  paymentGatewayList = [];
  firstCaseId;
  private caseDetails$ = this.caseDetailsSB.asObservable();
  ionNav = null;
  paymentData = {
    paymentGatewayList: [],
    caseId: null,
    caseData: null,
    fromHome: true,
    paymentOption: null,
    paymentFrequencyOption: null,
    paymentFrequency: null,
    selectedGateway: null,
    dateType: null,
    date: null,
    text: null,
    amount: null,
    freq: null,
    customPlan: null,
    paymentMethod: null,
    address: null,
    scheduledAmount: null,
    limit: null,
    total: 0,
    caseList:[],
  };
  constructor(private http: HttpClient, private commonService: CommonService) {}


  /** @see LU-309 show Tax payer key in Accounr Details page for AFCS
   * @param string client
   * @return boolean
  */
  isClient(client: string): boolean {
    const url = new URL(localStorage.getItem('server_url'));
    const subDomain  = url.hostname.split('.').shift();
    return subDomain === client;
  }

  /**
   * Allow only group arrangement when multiple account link
   * @param boolean isGroupArrangement
   * @returns boolean
   */
  allowOnlyGroupArrangement(isGroupArrangement: boolean): boolean {
    return (isGroupArrangement) && (this.caseList.length > 1);
  }

  getCaseDetails(debtor_id) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_details?debtor_id=${debtor_id}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  saveCaseDetails(debtor_id) {
    this.commonService.showLoader('Loading ...', 2000);
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_details?debtor_id=${debtor_id}&source=debtor_API`;
    this.http.get(apiURL).subscribe((res: any) => {
      if (res && res.result) {
        this.caseDetails = res.data;
        this.caseList = Object.values(res.data.caseList);
        this.paymentGatewayList = res.data.paymentGatewayList;
        this.setPaymentData({
          paymentGatewayList: this.paymentGatewayList,
        });
        localStorage.setItem('dateFormat', res.data.dateFormat);
        this.firstCaseId = this.caseList[0]?.['id'];
      }
      this.caseDetailsSB.next(res);
      this.commonService.dismissLoader();
    });
  }

  getCaseDetailsData() {
    return this.caseDetails$;
  }

  setMyNav(ionNav) {
    this.ionNav = ionNav;
  }

  getMyNav() {
    return this.ionNav;
  }

  setPaymentData(data) {
    this.paymentData = { ...this.paymentData, ...data };
  }

  getPaymentData() {
    return this.paymentData;
  }

  getDebtorDetails(debtor_id) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_debtor_details?debtor_id=${debtor_id}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  getCaseCustomVariables(case_id, regenerate = false) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_custom_variables?case_id=${case_id}&regenerate=${regenerate}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  getCaseVariables(case_id, regenerate = false) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_variables?case_id=${case_id}&regenerate=${regenerate}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  paymentPlan(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payment_plan?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  contactForm(data) {
    let formData = new FormData();
    formData.append('note',data.note);
    formData.append('caseid',data.caseid);
    if(data.type){
      formData.append('type',data.type);
    }
    if(data.attachments.length>0){
        data.attachments.forEach(attachment => {
        formData.append('attachments[]',attachment);
       });
    }
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/contact_form?source=debtor_API`;
    return this.http.post(apiURL, formData);
  }

  getGovUKPayLink(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/govuk_pay_link?source=debtor_API`;
    return this.http.post(apiURL, data);
  }
  
  getMobipaidLink(data){
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/mobipaid_link?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeUSAePayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeUSAePaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makePayrocPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payroc_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makePayRocPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payroc_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeTrxServicesPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/trx_services_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeTrxServicesPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/trx_services_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  createArrangement(type, caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/arrangements/case_actions_panels/${type}/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }
  
  previewArrangementPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/arrangements/case_actions_panels/preview/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  newRecurringCardPaymentPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/case_action_recurring_payment/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  newRecurringACHPaymentPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/case_action_recurring_ach_payment/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeStripePaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/stripe_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeStripePayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/stripe_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  addStripeCustomer(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/add_stripe_customer/`;
    return this.http.post(apiURL, data);
  }

  makeUSAeAchRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_ach_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  addBankAccount(debtor_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/add_bank_account?debtor_id=${debtor_id}&source=debtor_API`;
      return this.http.post(apiURL, data);
  }

  makeGocardlessPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/gocardless_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeGoCardlessPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/gocardless_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }
    
  makeAcquiredPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/acquired_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeAcquiredPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/acquired_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  maketrxAchRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/trx_ach_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  calculateTrxServiceFee(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/calculate_service_fee??case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  getCaseDocuments(caseId) {
    const apiURL = localStorage.getItem('server_url') + `b/clickdebt_ajax_layout/legacy/panels/case_documents/${caseId}?source=debtor_API`;
    return this.http.get(apiURL);
  }
  uploadDocument(file, caseId, fileName = '', doc_type = '') {
    let formData = new FormData();
    if(doc_type){
      formData.append('document_category',doc_type);
    }
    if(fileName) {
      formData.append('file', file, fileName);
      formData.append('doc_name',fileName);
    } else {
      formData.append('file', file);
    }
    const apiURL = localStorage.getItem('server_url') + `b/clickdebt_ajax_layout/legacy/panels/upload_case_documents/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, formData);
  }
  deleteDocument(documentId){
    const apiURL=localStorage.getItem('server_url') +`b/clickdebt_panel_layout/legacy/documents/delete_document/${documentId}?source=debtor_API`;
    return this.http.delete(apiURL);
  }

  reverifyGatewayPaymentFee($data) {
    const apiURL=localStorage.getItem('server_url') +`b/system/v3/debtor_api/reverify_gateway_payment_fee?source=debtor_API`;
    return this.http.post(apiURL, $data);
  }

  getGroupArrangement(data){
    const apiURL =
    localStorage.getItem('server_url') +
    `b/system/v3/debtor_api/get_group_case_arrangement?source=debtor_API`;
  return this.http.post(apiURL, data);
  }

  communicationPreferences(caseId, data){
    const apiURL =
    localStorage.getItem('server_url') +
    `b/system/v3/debtor_api/communication_preferences?case_id=${caseId}&source=debtor_API`;
  return this.http.post(apiURL, data);
  }
}
